/**
 * NavBarComponent is a functional component that renders a responsive navigation bar
 * using React Bootstrap, React Router, Framer Motion, and styled-components.
 * 
 * @param {NavBarProps} props - The properties for the NavBarComponent.
 * @param {boolean} props.isScrolled - A boolean indicating if the page has been scrolled.
 * 
 * @returns {JSX.Element} The rendered navigation bar component.
 * 
 * The navigation bar includes the following features:
 * - Responsive design with a collapsible menu for smaller screens.
 * - Dynamic background color and text color based on the `isScrolled` prop.
 * - Animated brand logo using Framer Motion.
 * - Styled navigation links with hover effects and active state indication.
 * - Navigation items are defined in the `navItems` array, each with a path and label.
 * - The navigation bar is fixed at the top of the page.
 */
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FaPhoneAlt, FaTimes } from 'react-icons/fa';
import logo from '../../assets/logo.webp';
import '../../styles/NavBarComponent.scss';

interface NavBarProps {
  isScrolled: boolean;
}

const NavBarComponent: React.FC<NavBarProps> = ({ isScrolled }) => {
  const [expanded, setExpanded] = useState(false);
  const [showEmergencyBar, setShowEmergencyBar] = useState(true);
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/services/commercial', label: 'Commercial' }, // Added Commercial to nav items
    { path: '/about', label: 'About' },
    { path: '/financing', label: 'Financing' },
    { path: '/blog', label: 'Blog' },
    { path: '/faq', label: 'FAQ' } //move service to the top by home. 
  ];

  const servicesItems = [
    { path: '/services/heating', label: 'Heating', icon: '🔥' },
    { path: '/services/cooling', label: 'Cooling', icon: '❄️' },
    { path: '/services/maintenance', label: 'Maintenance', icon: '🔧' },
    { path: '/services/indoor-air', label: 'Indoor Air Quality', icon: '💨' },
    { path: '/services/geothermal', label: 'Geothermal', icon: '🌍' },
    { path: '/services/water-heater-services', label: 'Water Heater Services', icon: '💧' },
    { path: '/services/electrical-services', label: 'Electrical Services', icon: '⚡' },
    { path: '/generac-services', label: 'Generac Generators', icon: '⚡' }
  ];

  // Add scroll effect
  useEffect(() => {
    if (expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [expanded]);

  const handleToggle = () => {
    setExpanded(!expanded);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showEmergencyBar && (
        <div className="emergency-bar" role="alert">
          <Container>
            <div className="d-flex justify-content-center align-items-center px-3">
              <div className="d-flex align-items-center">
                <FaPhoneAlt className="me-2 emergency-icon" aria-hidden="true" />
                <a href="tel:+18164275007" className="emergency-phone">
                  <span className="d-none d-md-inline">Professional HVAC Services - </span>
                  (816) 427-5007
                </a>
              </div>
            </div>
            <button
              className="close-btn"
              onClick={() => setShowEmergencyBar(false)}
              aria-label="Close emergency bar"
            >
              <FaTimes />
            </button>
          </Container>
        </div>
      )}

      {/* Add overlay for mobile menu */}
      <div
        className={`navbar-overlay ${expanded ? 'show' : ''}`}
        onClick={() => setExpanded(false)}
      />

      <Navbar
        expand="lg"
        fixed="top"
        expanded={expanded}
        className={`main-nav improved-nav ${isScrolled ? 'scrolled' : 'not-scrolled'}`}
        style={{ marginTop: showEmergencyBar ? '40px' : '0' }}
      >
        <Container fluid="lg">
          <Navbar.Brand as={Link} to="/" className="py-2 d-flex align-items-center">
            <img src={logo} alt="PennTech Logo" className="nav-logo" />
          </Navbar.Brand>

          {/* Moved the phone button to the right side */}
          <div className="d-flex align-items-center d-lg-none">
            <Button
              variant="Warning"
              size="sm"
              href="tel:+18164275007"
              className="emergency-mobile-btn me-2"
              aria-label='Call PennTech'
            >
              <FaPhoneAlt />
            </Button>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-lg-center">
              {navItems.map((item) => (
                <Nav.Link
                  key={item.path}
                  as={Link}
                  to={item.path}
                  className={`nav-link ${location.pathname === item.path ? 'active' : ''}`}
                  onClick={() => setExpanded(false)}
                >
                  {item.label}
                </Nav.Link>
              ))}


              <Button
                variant="outline-primary"
                size="sm"
                href="/contact"
                className="d-none d-lg-inline-flex ms-2"
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded(false);
                  window.location.href = '/contact';
                }}
              >
                Contact Us
              </Button>


              <NavDropdown
                title="Services"
                id="services-dropdown"
                className="services-dropdown bold-services"
                align="end"
              >
                <div className="mega-dropdown-menu">
                  <Container>
                    <div className="dropdown-grid">
                      {servicesItems.map((item) => (
                        <Link
                          key={item.path}
                          to={item.path}
                          className="grid-item"
                          onClick={() => setExpanded(false)}
                        >
                          <span className="item-icon">{item.icon}</span>
                          <span className="item-label">{item.label}</span>
                        </Link>
                      ))}
                    </div>
                  </Container>
                </div>
              </NavDropdown>



            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBarComponent;