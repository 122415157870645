import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { BlogPost as BlogPostType } from '../../types/blog.types';
import { Helmet } from 'react-helmet';
import './BlogPost.scss';

const API_URL = import.meta.env.PROD
    ? 'https://www.penntechinc.com/api/posts'
    : 'http://localhost:3012/api/posts';

const PLACEHOLDER_IMAGE = 'https://images.pexels.com/photos/1586496/pexels-photo-1586496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

const BlogPost = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState<BlogPostType | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Add this line to scroll to top on component mount

        const fetchPost = async () => {
            try {
                setIsLoading(true);
                console.log('Fetching post with ID:', id); // Debug log
                const response = await axios.get(`${API_URL}/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
                console.log('Response data:', response.data); // Debug log
                setPost(response.data);
                setError(null);
            } catch (err: unknown) {
                console.error('Error fetching post:', err);
                if (err instanceof AxiosError) {
                    setError(err.response?.data?.message || 'Failed to load blog post');
                } else {
                    setError('Failed to load blog post');
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (id) {
            fetchPost();
        }
    }, [id, navigate]);

    const handleBackClick = () => {
        window.scrollTo(0, 0);
        navigate('/blog');
    };

    if (isLoading) return <div className="blog-post-loading">Loading...</div>;
    if (error) return <div className="blog-post-error">Error: {error}</div>;
    if (!post) return <div className="blog-post-not-found">Post not found</div>;

    return (
        <>
            <Helmet>
                <title>{post.title} | PennTech HVAC</title>
                <meta name="description" content={post.summary} />
            </Helmet>

            <article className="blog-post">
                <h1>{post.title}</h1>
                <div className="post-meta">
                    <span>By {post.author}</span>
                    <span>{new Date(post.date).toLocaleDateString()}</span>
                </div>
                <img
                    src={post.image || PLACEHOLDER_IMAGE}
                    alt={post.title}
                    className="post-image"
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = PLACEHOLDER_IMAGE;
                    }}
                />
                <div className="post-content">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {post.content}
                    </ReactMarkdown>
                </div>
                <div className="post-navigation">
                    <button onClick={handleBackClick} className="back-to-blog">
                        ← Back to Blog
                    </button>
                </div>
            </article>
        </>
    );
};

export default BlogPost;
