import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope, } from 'react-icons/fa';
import '../styles/CallToAction.scss';

const CallToAction: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            console.log(`Scroll Position: ${scrollPosition}, Window Height: ${windowHeight}`);
            if (scrollPosition > windowHeight * 0.25) {
                console.log('Setting visible to true');
                setIsVisible(true);
            } else {
                console.log('Setting visible to false');
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <button
                className={`floating-action-button ${isVisible ? 'visible' : 'hidden'}`}
                onClick={() => setShowModal(true)}
                aria-label="Contact Us"
            >
                <FaPhoneAlt />
            </button>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                className="cta-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cta-modal-content">
                        <div className="cta-action-button">
                            <a href="tel:+18164275007" className="action-button phone">
                                <FaPhoneAlt /> Call Now
                                <small>(816) 427-5007</small>
                            </a>
                        </div>
                        <div className="cta-action-button">
                            <a
                                href="https://book.housecallpro.com/book/PennTech-Inc/b96d2f64ff3b4e94b2b2bb9419e82c8c"
                                className="action-button schedule"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaEnvelope /> Schedule Service
                                <small>Book Online </small>
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CallToAction;
