import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavBarComponent from './components/nav/NavBarComponent';
import Footer from './components/Footer';
import PreLoader from './components/PreLoader';
import CallToAction from './components/CallToAction';
import BlogPage from './components/blog-page/BlogPage';
import AdminLogin from './components/admin-login/AdminLogin';
import AdminPage from './components/admin-page/AdminPage';
import BlogPostComponent from './components/blog-post/BlogPost';

// Lazy load all other components
const Home = lazy(() => import('./pages/Home'));
const Heating = lazy(() => import('./pages/Heating'));
const Cooling = lazy(() => import('./pages/Cooling'));
const RenewableEnergy = lazy(() => import('./pages/RenewableEnergy'));
const AllServices = lazy(() => import('./pages/AllServices'));
const Financing = lazy(() => import('./pages/Financing'));
const Contact = lazy(() => import('./pages/Contact'));
const WaterHeaterServices = lazy(() => import('./pages/WaterHeaterServices'));
const ElectricalServices = lazy(() => import('./pages/ElectricalServices'));
const About = lazy(() => import('./pages/About'));
const GeneracServices = lazy(() => import('./pages/GeneracServices'));
const CleanerEnergy = lazy(() => import('./pages/CleanerEnergy'));
const FAQp = lazy(() => import('./pages/FAQp'));
const Blog = lazy(() => import('./components/blog_back/Blog'));
const HiddenPage = lazy(() => import('./pages/HiddenPage'));
const BlogPost = lazy(() => import('./components/blog_back/BlogPost'));
const Commercial = lazy(() => import('./pages/Commercial'));

const Maintenance = lazy(() => import('./pages/Maintenance')); // Corrected import
const ComingSoon = lazy(() => import('./pages/Commingsoon'));
const ServiceAreas = lazy(() => import('./pages/ServiceAreas'));
const EnergyAudit = lazy(() => import('./pages/EnergyAudit'));

// Add Protected Route component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > 50);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      AOS.refresh();
    };
  }, [handleScroll]);

  useEffect(() => {
    AOS.refresh();
  }, [location]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simulate loading time
  }, []);

  return (
    <>
      {!loading && (
        <>
          <NavBarComponent isScrolled={isScrolled} />
          <div style={{ paddingTop: '70px' }}> {/* To offset the fixed navbar */}
            <Suspense fallback={<PreLoader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services/heating" element={<Heating />} />
                <Route path="/services/cooling" element={<Cooling />} />
                <Route path="/services/renewable-energy" element={<RenewableEnergy />} />
                <Route path="/services/all-services" element={<AllServices />} />
                <Route path="/financing" element={<Financing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<FAQp />} />
                <Route path="/services/water-heater-services" element={<WaterHeaterServices />} />
                <Route path="/services/electrical-services" element={<ElectricalServices />} />
                <Route path="/about" element={<About />} />
                <Route path="/generac-services" element={<GeneracServices />} />
                <Route path="/services/cleaner-energy" element={<CleanerEnergy />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:id" element={<BlogPostComponent />} />
                <Route path="/hidden" element={<HiddenPage />} />
                <Route path="/services/maintenance" element={<Maintenance />} /> {/* Corrected path */}
                <Route path="/services/indoor-air" element={<ComingSoon />} />
                <Route path="/services/ductwork" element={<ComingSoon />} />
                <Route path="/service-areas" element={<ServiceAreas />} />
                <Route path="/services/energy-audit" element={<EnergyAudit />} />
                <Route path="/services/commercial" element={<Commercial />} /> {/* Added route for Commercial */}

                {/* Redirect routes for SEO */}
                <Route path="/blue-springs-mo/ac-heating-thermostat-repair" element={<Navigate to="/service-areas" />} />
                <Route path="/lees-summit-mo/electricians" element={<Navigate to="/service-areas" />} />
                <Route path="/heating-air-conditioning" element={<Navigate to="/service-areas" />} />
                <Route path="/air-conditioning-blue-springs" element={<Navigate to="/service-areas" />} />
                <Route path="/cooling-system" element={<Navigate to="/service-areas" />} />
                <Route path="/construction-and-contractors" element={<Navigate to="/service-areas" />} />
                <Route path="/electrical-repair" element={<Navigate to="/services/all-services" />} />
                <Route path="/index.php/energy-efficiency-resources" element={<Navigate to="/services/energy-audit" />} />
                <Route path="/index.php/ac" element={<Navigate to="/services/cooling" />} />
                <Route path="/geothermal" element={<Navigate to="/services/renewable-energy" />} />
                <Route path="/water-heater" element={<Navigate to="/services/water-heater-services" />} />
                <Route path="/electrical" element={<Navigate to="/services/electrical-services" />} />
                <Route path="/blue-springs-mo-heating-and-cooling" element={<Navigate to="/service-areas" />} />
                <Route path="/the-benefits-of-routine-hvac-maintenance" element={<Navigate to="/services/maintenance" />} />
                <Route path="/commercial-hvac" element={<Navigate to="/services/all-services" />} />
                <Route path="/heating" element={<Navigate to="/services/heating" />} />
                <Route path="/cooling" element={<Navigate to="/services/cooling" />} />
                <Route path="/generators" element={<Navigate to="/generac-services" />} />
                <Route path="/solar-energy" element={<Navigate to="/services/renewable-energy" />} />
                <Route path="/careers" element={<Navigate to="/about" />} />
                <Route path="/hvac" element={<Navigate to="/services/all-services" />} />
                <Route path="/cooling-services" element={<Navigate to="/services/cooling" />} />
                <Route path="/hvac-services" element={<Navigate to="/services/all-services" />} />
                <Route path="/gallery" element={<Navigate to="/about" />} />

                {/* Admin Routes */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="/admin/*"
                  element={
                    <ProtectedRoute>
                      <Routes>
                        <Route index element={<AdminPage />} />
                        {/* Add more admin routes here as needed */}
                      </Routes>
                    </ProtectedRoute>
                  }
                />

                {/* Fallback Route */}
                {/* <Route path="*" element={<Navigate to="/" />} /> */} {/* Catch-all route */}
              </Routes>
            </Suspense>
          </div>
          {/* Don't show CTA on admin pages */}
          {!location.pathname.startsWith('/admin') && <CallToAction />}
          {/* Don't show Footer on admin pages */}
          {!location.pathname.startsWith('/admin') && <Footer />}
        </>
      )}
    </>
  );
};

const AppWrapper: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;

// Export the router for sitemap generation
export const router = (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services/heating" element={<Heating />} />
      <Route path="/services/cooling" element={<Cooling />} />
      <Route path="/services/renewable-energy" element={<RenewableEnergy />} />
      <Route path="/services/all-services" element={<AllServices />} />
      <Route path="/financing" element={<Financing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQp />} />
      <Route path="/services/water-heater-services" element={<WaterHeaterServices />} />
      <Route path="/services/electrical-services" element={<ElectricalServices />} />
      <Route path="/about" element={<About />} />
      <Route path="/generac-services" element={<GeneracServices />} />
      <Route path="/services/cleaner-energy" element={<CleanerEnergy />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogPost />} />
      <Route path="/hidden" element={<HiddenPage />} />
      <Route path="/services/maintenance" element={<Maintenance />} /> {/* Corrected path */}
      <Route path="/services/indoor-air" element={<ComingSoon />} />
      <Route path="/services/ductwork" element={<ComingSoon />} />
      <Route path="/service-areas" element={<ServiceAreas />} />
      <Route path="/services/energy-audit" element={<EnergyAudit />} />
      <Route path="/services/commercial" element={<Commercial />} /> {/* Added route for Commercial */}

      {/* Add the same redirect routes here */}
      <Route path="/electrical-repair" element={<Navigate to="/services/all-services" />} />
      <Route path="/index.php/energy-efficiency-resources" element={<Navigate to="/services/energy-audit" />} />
      <Route path="/index.php/ac" element={<Navigate to="/services/cooling" />} />
      <Route path="/geothermal" element={<Navigate to="/services/renewable-energy" />} />
      <Route path="/water-heater" element={<Navigate to="/services/water-heater-services" />} />
      <Route path="/electrical" element={<Navigate to="/services/electrical-services" />} />
      <Route path="/blue-springs-mo-heating-and-cooling" element={<Navigate to="/service-areas" />} />
      <Route path="/the-benefits-of-routine-hvac-maintenance" element={<Navigate to="/services/maintenance" />} />
      <Route path="/commercial-hvac" element={<Navigate to="/services/all-services" />} />
      <Route path="/heating" element={<Navigate to="/services/heating" />} />
      <Route path="/cooling" element={<Navigate to="/services/cooling" />} />
      <Route path="/generators" element={<Navigate to="/generac-services" />} />
      <Route path="/solar-energy" element={<Navigate to="/services/renewable-energy" />} />
      <Route path="/careers" element={<Navigate to="/about" />} />
      <Route path="/hvac" element={<Navigate to="/services/all-services" />} />
      <Route path="/cooling-services" element={<Navigate to="/services/cooling" />} />
      <Route path="/hvac-services" element={<Navigate to="/services/all-services" />} />
      <Route path="/gallery" element={<Navigate to="/about" />} />

      {/* Add admin routes to sitemap (optional, you might want to exclude these) */}
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin" element={<AdminPage />} />

      <Route path="*" element={<Navigate to="/" />} /> {/* Catch-all route */}
    </Routes>
  </Router>
);