import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BlogPost } from '../../types/blog.types';
import ReactMarkdown from 'react-markdown';
import { FaEdit, FaTrash, FaEye, FaSave, FaTimes, FaPlus } from 'react-icons/fa';
import '../../styles/admin.scss';
import { API_ENDPOINTS, getAuthHeaders } from '../../config/api';

const AdminPage = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [editMode, setEditMode] = useState<string | null>(null);
    const [previewMode, setPreviewMode] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // Form states
    const [editForm, setEditForm] = useState({
        title: '',
        summary: '',
        content: '',
        image: '',
        date: '',
        author: ''
    });

    const token = localStorage.getItem('token') || '';

    const fetchPosts = useCallback(async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.posts, {
                headers: getAuthHeaders(token)
            });
            setPosts(response.data);
            setError(null);
        } catch (err) {
            console.error('Error fetching posts:', err);
            setError('Failed to fetch posts');
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const handleEdit = (post: BlogPost) => {
        setEditMode(post.id);
        setEditForm({
            title: post.title,
            summary: post.summary,
            content: post.content,
            image: post.image,
            date: post.date,
            author: post.author
        });
    };

    const handleUpdate = async (id: string) => {
        try {
            await axios.put(API_ENDPOINTS.post(id), editForm, {
                headers: getAuthHeaders(token)
            });
            fetchPosts();
            setEditMode(null);
        } catch (err) {
            console.error('Error updating post:', err);
            setError('Failed to update post');
        }
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await axios.delete(API_ENDPOINTS.post(id), {
                    headers: getAuthHeaders(token)
                });
                setPosts(posts.filter(post => post.id !== id));
            } catch (err) {
                console.error('Error deleting post:', err);
                setError('Failed to delete post');
            }
        }
    };

    return (
        <div className="admin-dashboard">
            <header className="admin-dashboard__header">
                <div className="header-content">
                    <h1>Blog Management</h1>
                    <p className="stats">
                        Total Posts: {posts.length} | Published: {posts.filter(p => new Date(p.date) <= new Date()).length}
                    </p>
                </div>
                <button className="admin-btn admin-btn--primary" onClick={() => setEditMode('new')}>
                    <FaPlus /> Create New Post
                </button>
            </header>

            {error && <div className="admin-error">{error}</div>}

            <div className="post-management">
                {loading ? (
                    <div className="admin-loading">
                        <div className="loading-spinner"></div>
                        <p>Loading posts...</p>
                    </div>
                ) : (
                    <div className="post-list">
                        {posts.map((post) => (
                            <div key={post.id} className="post-item">
                                {editMode === post.id ? (
                                    <div className="post-edit-form">
                                        <input
                                            type="text"
                                            value={editForm.title}
                                            onChange={e => setEditForm({ ...editForm, title: e.target.value })}
                                            className="admin-input"
                                            placeholder="Post Title"
                                        />
                                        <input
                                            type="text"
                                            value={editForm.summary}
                                            onChange={e => setEditForm({ ...editForm, summary: e.target.value })}
                                            className="admin-input"
                                            placeholder="Summary"
                                        />
                                        <div className="editor-toolbar">
                                            <button onClick={() => setPreviewMode(post.id)}>
                                                Preview
                                            </button>
                                        </div>
                                        <textarea
                                            value={editForm.content}
                                            onChange={e => setEditForm({ ...editForm, content: e.target.value })}
                                            className="admin-textarea"
                                            placeholder="Post Content (Markdown supported)"
                                        />
                                        {previewMode === post.id && (
                                            <div className="markdown-preview">
                                                <ReactMarkdown>{editForm.content}</ReactMarkdown>
                                            </div>
                                        )}
                                        <div className="form-actions">
                                            <button
                                                onClick={() => handleUpdate(post.id)}
                                                className="admin-btn admin-btn--primary"
                                            >
                                                <FaSave /> Save
                                            </button>
                                            <button
                                                onClick={() => setEditMode(null)}
                                                className="admin-btn admin-btn--secondary"
                                            >
                                                <FaTimes /> Cancel
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="post-display">
                                        <h3>{post.title}</h3>
                                        <p className="post-meta">
                                            {new Date(post.date).toLocaleDateString()} | {post.author}
                                        </p>
                                        <div className="post-actions">
                                            <button
                                                onClick={() => handleEdit(post)}
                                                className="admin-btn admin-btn--secondary"
                                            >
                                                <FaEdit /> Edit
                                            </button>
                                            <button
                                                onClick={() => handleDelete(post.id)}
                                                className="admin-btn admin-btn--danger"
                                            >
                                                <FaTrash /> Delete
                                            </button>
                                            <button
                                                onClick={() => setPreviewMode(post.id)}
                                                className="admin-btn admin-btn--secondary"
                                            >
                                                <FaEye /> Preview
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPage;


