import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/PreLoader.scss';
import logo from '../assets/logo.webp';

function isBotUserAgent() {
    return /bot|crawler|spider|crawling/i.test(navigator.userAgent);
}

const PreLoader: React.FC<{ onLoadComplete?: () => void }> = ({ onLoadComplete }) => {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const isMobile = window.innerWidth <= 480;

    useEffect(() => {
        if (location.pathname !== '/') {
            setIsLoading(false);
            return;
        }

        if (isBotUserAgent()) {
            setIsLoading(false);
            onLoadComplete?.();
            return;
        }

        setTimeout(() => {
            setIsLoading(false);
            onLoadComplete?.();
        }, isMobile ? 1500 : 2000); // Faster on mobile
    }, [onLoadComplete, location.pathname]);

    return (
        <div className={`preloader ${!isLoading ? 'fade-out' : ''}`}>
            <div className="content-wrapper">
                <div className="logo-container">
                    <img src={logo} alt="Company Logo" />
                </div>
                <div className="welcome-message">Welcome to PennTech HVAC</div>
            </div>
        </div>
    );
};

export default PreLoader;