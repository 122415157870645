import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import '../../styles/admin.scss';

const AdminLogin: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await axios.post(API_ENDPOINTS.login, {
                username,
                password
            });
            localStorage.setItem('token', response.data.token);
            window.location.href = '/admin';
        } catch (err) {
            console.error('Login error:', err);
            setError('Invalid credentials. Please try again.');
        }
    };

    return (
        <div className="admin-login">
            <form className="admin-login__form" onSubmit={handleLogin}>
                <h1>Admin Login</h1>
                {error && <div className="error-message">{error}</div>}
                <div className="admin-form__group">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="admin-form__group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="admin-btn admin-btn--primary">
                    Login
                </button>
            </form>
        </div>
    );
};

export default AdminLogin;