import React from 'react';
import { BlogPost } from '../../types/blog.types';
import { useNavigate } from 'react-router-dom';
import './BlogCard.scss';

interface BlogCardProps {
    post: BlogPost;
}

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        window.scrollTo(0, 0);
        navigate(`/blog/${post.id}`);
    };

    const excerpt = post.content.substring(0, 200) + '...';

    return (
        <article className="blog-card" itemScope itemType="http://schema.org/BlogPosting">
            <meta itemProp="datePublished" content={post.date} />
            <meta itemProp="author" content={post.author} />
            <div className="blog-card__image">
                <img src={post.image} alt={post.title} itemProp="image" />
            </div>
            <div className="blog-card__content">
                <h2 itemProp="headline">{post.title}</h2>
                <div className="blog-card__meta">
                    <span>{new Date(post.date).toLocaleDateString()}</span>
                    <span>By {post.author}</span>
                </div>
                <p itemProp="description">{post.summary}</p>
                <div className="blog-card__excerpt">
                    {excerpt}
                </div>
                <button onClick={handleClick} className="blog-card__read-more">
                    Read More
                </button>
            </div>
        </article>
    );
};

export default BlogCard;
