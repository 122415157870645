import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import '../styles/Footer.scss';
import logo from '../assets/logo-white.webp'; // Make sure you have a white version of the logo

const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  return (
    <div className="footer-master-wrapper">
      <footer className="footer-wrapper">
        <div className="footer-top py-5">
          <Container>
            <Row className="gy-4">
              <Col lg={4} md={6} className="mb-4 mb-md-0">
                <div className="footer-brand mb-4">
                  <img
                    src={logo}
                    alt="PennTech HVAC Services Logo"
                    height="90"
                    width="auto"
                    className="footer-logo"
                  />
                </div>
                <p className="text-description mb-4">
                  PennTech has provided quality Mechanical, Electrical and Solar Energy services, repairs, and installation for residential and commercial customers in Missouri and Kansas - Kansas City Metro areas since 2009. As your trusted HVAC experts, we're licensed, bonded, and insured, delivering comfort and reliability to homes and businesses throughout Blue Springs, Independence, Raymore, Liberty, and surrounding communities.
                </p>
                <div className="social-links-wrapper">
                  <h6 className="text-white mb-3">Connect With Us</h6>
                  <div className="social-links">
                    <a href="https://www.facebook.com/PennTechInc"
                      aria-label='Facebook'
                      target="_blank"
                      rel="noopener noreferrer"
                      className="facebook">
                      <FaFacebookF />
                    </a>
                    <a href="https://www.google.com/maps/place/PennTech+Inc/@39.0170168,-94.2757247,15z/"
                      aria-label='Google Business'
                      target="_blank"
                      rel="noopener noreferrer"
                      className="google">
                      <FcGoogle />
                    </a>
                    <a href="https://www.linkedin.com/company/penntech-inc"
                      aria-label='LinkedIn'
                      target="_blank"
                      rel="noopener noreferrer"
                      className="linkedin">
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </Col>

              <Col lg={4} md={6} className="mb-4 mb-md-0">
                <h5 className="text-white mb-4">Quick Links</h5>
                <div className="footer-links">
                  <Link to="/services/cooling">Air Conditioning</Link>
                  <Link to="/services/heating">Heating Solutions</Link>
                  <Link to="/services/renewable-energy">Renewable Energy</Link>
                  <Link to="/services/water-heater-services">Water Heaters</Link>
                  <Link to="/services/electrical-services">Electrical Services</Link>
                  <Link to="/generac-services">Generac Generators</Link>
                  <Link to="/services/maintenance">Maintenance Plans</Link>
                  <Link to="/services/energy-audit">Energy Audits</Link>
                  <Link to="/financing">Financing Options</Link>
                  <Link to="/service-areas">Service Areas</Link>
                  <Link to="/about">About Us</Link>
                  <Link to="/contact">Contact Us</Link>
                </div>
              </Col>

              <Col lg={4} md={12}>
                <h5 className="text-white mb-4">Contact Information</h5>
                <div className="contact-info-wrapper">
                  <div className="contact-info">
                    <div className="contact-details">
                      <p className="phone-wrapper">
                        <FaPhoneAlt className="icon" />
                        <a href="tel:+18164275007" className="primary-phone" aria-label="Call PennTech">
                          (816) 427-5007
                        </a>
                      </p>
                      <p>
                        <FaEnvelope className="icon" />
                        <a href="mailto:service@penntechinc.com">service@penntechinc.com</a>
                      </p>
                      <p>
                        <FaMapMarkerAlt className="icon" />
                        <span className="address">
                          1755 SE County Rd AA Unit A,<br />
                          Blue Springs, MO 64014
                        </span>
                      </p>
                    </div>
                    <div style={{ fontSize: '0.9rem' }}>

                      <table className="hours-table">
                        <tbody>
                          <tr>
                            <td>Monday</td>
                            <td>7:30 AM - 5 PM</td>
                          </tr>
                          <tr>
                            <td>Tuesday</td>
                            <td>7:30 AM - 5 PM</td>
                          </tr>
                          <tr>
                            <td>Wednesday</td>
                            <td>7:30 AM - 5 PM</td>
                          </tr>
                          <tr>
                            <td>Thursday</td>
                            <td>7:30 AM - 5 PM</td>
                          </tr>
                          <tr>
                            <td>Friday</td>
                            <td>7:30 AM - 5 PM</td>
                          </tr>
                          <tr>
                            <td>Saturday</td>
                            <td>Closed</td>
                          </tr>
                          <tr>
                            <td>Sunday</td>
                            <td>Closed</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="footer-bottom py-3">
          <Container>
            <Row>
              <Col className="text-center">
                <p className="mb-0">
                  © {new Date().getFullYear()} PennTech HVAC Services. All rights reserved.
                  <span className="mx-2">|</span>
                  <Link to="/privacy">Privacy Policy</Link>
                </p>
              </Col>
            </Row>
            <div className="text-center mt-3">
              <small>
                © {new Date().getFullYear()} PennTech - Serving Blue Springs, Independence, Raymore, Liberty, and the surrounding Missouri areas.
              </small>
            </div>
          </Container>
        </div>
      </footer>

      <Modal show={showModal} onHide={handleModalClose} centered className="contact-modal">
        <Modal.Header closeButton>
          <Modal.Title>Contact Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact-info">
            <p>
              <FaPhoneAlt className="icon" />
              <a href="tel:+18164275007" className="emergency-phone">
                (816) 427-5007
              </a>
            </p>
            <p>
              <FaEnvelope className="icon" />
              <a href="mailto:service@penntechinc.com">service@penntechinc.com</a>
            </p>
            <p>
              <FaMapMarkerAlt className="icon" />
              1755 SE County Rd AA Unit A,<br />
              Blue Springs, MO 64014
            </p>
            <div style={{ fontSize: '0.9rem' }}>
              <strong>Hours:</strong>
              <table className="hours-table">
                <tbody>
                  <tr>
                    <td>Monday</td>
                    <td>7:30 AM - 5 PM</td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>7:30 AM - 5 PM</td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>7:30 AM - 5 PM</td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>7:30 AM - 5 PM</td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>7:30 AM - 5 PM</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <td>Sunday</td>
                    <td>Closed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;
