import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { BlogPost } from '../../types/blog.types';
import BlogCard from '../blog-card/BlogCard';
import './BlogPage.scss';  // Update this line

// API URL based on environment
const API_URL = import.meta.env.PROD
    ? 'https://www.penntechinc.com/api/posts'
    : 'http://localhost:3012/api/posts';

const BlogPage = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        axios.get<BlogPost[]>(API_URL, {
            headers: {
                'Accept': 'application/json'
            },
            timeout: 10000 // 10 second timeout
        })
            .then(response => {
                if (response.data) {
                    setPosts(response.data);
                    setError(null);
                } else {
                    setError('No posts found');
                }
            })
            .catch(error => {
                const errorMessage = error.response?.status === 404
                    ? 'No posts found'
                    : 'Failed to fetch posts. Please try again later.';
                setError(errorMessage);
                console.error('Error fetching posts:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>HVAC Tips & Services Blog | PennTech HVAC Blue Springs</title>
                <meta name="description" content="Expert HVAC maintenance tips, repair guides, and energy-saving advice for Blue Springs, MO residents. Stay comfortable year-round with PennTech's professional insights." />
                <link rel="canonical" href="https://penntech.com/blog" />
                <meta property="og:title" content="HVAC Tips & Services Blog | PennTech HVAC Blue Springs" />
                <meta property="og:description" content="Expert HVAC maintenance tips, repair guides, and energy-saving advice for Blue Springs, MO residents." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://penntech.com/blog" />
            </Helmet>

            <div className="blog-container">
                <header className="blog-header">
                    <h1>HVAC Tips & Services Blog</h1>
                    <p className="blog-subtitle">Expert advice for maintaining your HVAC system in Blue Springs, MO</p>
                </header>

                <main className="blog-grid">
                    {isLoading ? (
                        <div className="loading">Loading...</div>
                    ) : error ? (
                        <div className="error">Error: {error}</div>
                    ) : (
                        posts.map(post => (
                            <BlogCard key={post.id} post={post} />
                        ))
                    )}
                </main>
            </div>
        </>
    );
};

export default BlogPage;
