export const API_BASE_URL = 'http://localhost:3012';

export const API_ENDPOINTS = {
    posts: `${API_BASE_URL}/api/posts`,
    login: `${API_BASE_URL}/api/admin/login`,
    post: (id: string) => `${API_BASE_URL}/api/posts/${id}`,
};

export const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`
});
